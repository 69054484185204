<template>
  <div id="">
    <div class="magazine">
      <img alt="Vue logo" class="u-p c-logo" src="@/assets/logo.png">
      <p class="u-pv">Swipe to see more pages</p>
      <div class="u-p">
        <div class="container">
          <swiper :options="swiperOption" id="magazine">
            <swiper-slide v-for="page in 114" :key="page.key">
              <img :src="require(`../../assets/summer2019/QUENCH-thirsty-gay-magazine-summer-2019_Page_${page.toString().padStart(3, '0')}.jpg`)" alt="">
            </swiper-slide>
            <div class="swiper-pagination" slot="pagination"></div>
          </swiper>
        </div>
      </div>
      <p class="u-pv">Need a PDF? Click <a class="u-color-white" href="/assets/QUENCH-thirsty-gay-magazine-summer-2019.pdf" target="_blank">here</a></p>
    </div>
    <q-footer />
  </div>
</template>
<script>
import QFooter from '@/components/QFooter.vue'
import 'swiper/dist/css/swiper.css'

import { swiper, swiperSlide } from 'vue-awesome-swiper'

export default {
  name: 'MagazineSummer2019',
  data: () => ({
    swiperOption: {
      pagination: {
        el: '.swiper-pagination',
        dynamicBullets: true,
      }
    }
  }),
  metaInfo: {
    title: 'Summer 2019 | The Thirsty Gay Magazine',
    meta: [
      {
        name: 'title',
        content: 'Summer 2019 | The Thirsty Gay Magazine'
      },
      {
        name: 'og:title',
        content: 'Summer 2019 | The Thirsty Gay Magazine'
      },
      {
        name: 'twitter:title',
        content: 'Summer 2019 | The Thirsty Gay Magazine'
      },
      {
        name: 'description',
        content: 'In the first issue of QUENCH, we explore the results of our fetish survey, talk with the creator of SportsMilitia, give you Tumblr alternatives and much more.'
      },
      {
        property: 'og:description',
        content: 'In the first issue of QUENCH, we explore the results of our fetish survey, talk with the creator of SportsMilitia, give you Tumblr alternatives and much more.'
      },
      {
        property: 'twitter:description',
        content: 'In the first issue of QUENCH, we explore the results of our fetish survey, talk with the creator of SportsMilitia, give you Tumblr alternatives and much more.'
      }
    ]
  },
  methods: {
    pad (n, width, z) {
      z = z || '0'
      n = n + ''
      return n.length >= width ? n : new Array(width - n.length + 1).join(z) + n
    }
  },
  components: {
    swiper,
    swiperSlide,
    QFooter
  }
}
</script>
<style lang="scss">
.container {
    position: relative;
    // overflow: hidden;
    padding-top: 177.7%;
    #magazine {
      box-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.25);
      position: absolute !important;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      border: 0;
    }
}
.embed {
  height: 100vh;
  width: 100%;
}
.swiper-pagination-bullet {
  background: rgba(#fff, 0.9);
}
.swiper-pagination-bullet-active {
  background: rgba(#fff, 0.7);
}
</style>
